<template>
  <div class="exhibition">
    <div class="hibitiontop">
      <div class="hibitionimg wow animate fadeInUp">
        <img class="immmon" src="../assets/img/alltitle/auone.png" alt="" />
      </div>

      <!-- <div class="centernav wow animate fadeInUp delay-300ms">
        <div class="centernavinner">
          <div class="selectarea">
            
            <img
              class="mapclass"
              src="../assets/img/alltitle/mapding.png"
              alt=""
            />
            <el-select
              v-model="value"
              :placeholder="placeholder1"
              @change="choosearea"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
            <div class="rightlion"></div>
            <img
              class="mapclass"
              src="../assets/img/alltitle/mapding.png"
              alt=""
            />
            <el-select
              v-model="two"
              :placeholder="placeholder2"
              @focus="plcechose"
              @change="choosesencond"
            >
              <el-option
                v-for="two in sencondselect"
                :key="two.value"
                :label="two.name"
                :value="two.name"
              >
              </el-option>
            </el-select>
            <div class="rightlion"></div>
            <img
              class="mapclass"
              src="../assets/img/alltitle/mapding.png"
              alt=""
            />
            <el-select
              v-model="there"
              :placeholder="placeholder3"
              @focus="behind"
              @change="choolast"
            >
              <el-option
                v-for="there in thirdselect"
                :key="there.value"
                :label="there.name"
                :value="there.name"
              >
              </el-option>
            </el-select>
          
          </div>

          <div class="selectblack" @click.stop="toinquery">
            {{ $t("login.ochaxue") }}
          </div>
        </div>
      </div> -->
      <div class="boxnew">
        <div class="oVictoria">Victoria</div>
        <div class="boxsonss">
          <div class="monkeyfahter wow animate fadeInUp delay-300ms">
            <div class="monkeybott">
              <div class="fistmonkeychild">
                <div class="copyrigo">
                  <img
                    class="imgoned"
                    src="../assets/img/alltitle/copyin.png"
                    alt=""
                  />
                  <span>FOTILE Glen Waverley Store</span>
                </div>
                <div class="telandfax telandfaxtwo">Tel: 0477 599 888</div>
                <div class="telandfax"></div>
                <div class="telandfax">
                  LG28, The Glen shopping Centre, 235 Springvale Rd, Glen
                  Waverley VIC 3150
                </div>
              </div>

              <div class="fistmonkeychild"></div>
            </div>
          </div>
          <div class="monkeyfahter wow animate fadeInUp delay-300ms">
            <div class="monkeybott">
              <div class="fistmonkeychild">
                <div class="copyrigo">
                  <img
                    class="imgoned"
                    src="../assets/img/alltitle/copyin.png"
                    alt=""
                  />
                  <span>FOTILE Box Hill Store</span>
                </div>
                <div class="telandfax telandfaxtwo">Tel: 0499 876 998</div>
                <div class="telandfax"></div>
                <div class="telandfax">
                  SP 020, Box Hill South Precinct, 1 Main St, Box Hill VIC 3128
                </div>
              </div>

              <div class="fistmonkeychild"></div>
            </div>
          </div>
          <div class="monkeyfahter wow animate fadeInUp delay-300ms">
            <div class="monkeybott">
              <div class="fistmonkeychild">
                <div class="copyrigo">
                  <img
                    class="imgoned"
                    src="../assets/img/alltitle/copyin.png"
                    alt=""
                  />
                  <span>FOTILE Camberwell Store</span>
                </div>
                <div class="telandfax telandfaxtwo">
                  Tel: 03 9939 9944 / 0422 032 980
                </div>
                <div class="telandfax"></div>
                <div class="telandfax">
                  620 Camberwell Rd, Camberwell, VIC 3124
                </div>
              </div>

              <div class="fistmonkeychild"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="boxnew">
        <div class="oVictoria">New South Wales</div>
        <div class="boxsonss">
          <div class="monkeyfahter wow animate fadeInUp delay-300ms">
            <div class="monkeybott">
              <div class="fistmonkeychild">
                <div class="copyrigo">
                  <img
                    class="imgoned"
                    src="../assets/img/alltitle/copyin.png"
                    alt=""
                  />
                  <span>FOTILE Burwood Store</span>
                </div>
                <div class="telandfax telandfaxtwo">
                  Tel: 0416 049 926 / 02 9744 0999
                </div>
                <div class="telandfax"></div>
                <div class="telandfax">
                  Shop 39A, Burwood Plaza, 42 Railway Parade, Burwood NSW 2134
                </div>
              </div>

              <div class="fistmonkeychild"></div>
            </div>
          </div>
          <div class="monkeyfahter wow animate fadeInUp delay-300ms">
            <div class="monkeybott">
              <div class="fistmonkeychild">
                <div class="copyrigo">
                  <img
                    class="imgoned"
                    src="../assets/img/alltitle/copyin.png"
                    alt=""
                  />
                  <span>FOTILE Carlinford Store</span>
                </div>
                <div class="telandfax telandfaxtwo">
                  Tel: 0466 346 618 / 02 8810 6530
                </div>
                <div class="telandfax"></div>
                <div class="telandfax">
                  Shop G13, Carlingford Village, 372 Pennant Hills Rd,
                  Cardlingford, NSW 2118
                </div>
              </div>

              <div class="fistmonkeychild"></div>
            </div>
          </div>
          <div class="monkeyfahter wow animate fadeInUp delay-300ms">
            <div class="monkeybott">
              <div class="fistmonkeychild">
                <div class="copyrigo">
                  <img
                    class="imgoned"
                    src="../assets/img/alltitle/copyin.png"
                    alt=""
                  />
                  <span>FOTILE Hurstville Store</span>
                </div>
                <div class="telandfax telandfaxtwo">
                  Tel: 0421 123 213 / 02 9580 5173
                </div>
                <div class="telandfax"></div>
                <div class="telandfax">
                  Shop 4, 11-13 Treacy Street, Hurstville NSW 2220
                </div>
              </div>

              <div class="fistmonkeychild"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="boxnew">
        <div class="oVictoria">Queensland</div>
        <div class="boxsonss">
          <div class="monkeyfahter wow animate fadeInUp delay-300ms">
            <div class="monkeybott">
              <div class="fistmonkeychild">
                <div class="copyrigo">
                  <img
                    class="imgoned"
                    src="../assets/img/alltitle/copyin.png"
                    alt=""
                  />
                  <span>Fotile Brisbane Store</span>
                </div>
                <div class="telandfax telandfaxtwo">
                  Tel: 0483191763 / 0412605679
                </div>
                <div class="telandfax"></div>
                <div class="telandfax">
                  HomeCo Logan, DELTA, Level 1, 3525 Pacific Highway, Slacks
                  Creek QLD 4127 (Next to Ikea)
                </div>
              </div>

              <div class="fistmonkeychild"></div>
            </div>
          </div>

          <div class="monkeyfahter wow animate fadeInUp delay-300ms">
            <div class="monkeybott">
              <div class="fistmonkeychild">
                <div class="copyrigo">
                  <img
                    class="imgoned"
                    src="../assets/img/alltitle/copyin.png"
                    alt=""
                  />
                  <span>Emma Kitchen World</span>
                </div>
                <div class="telandfax telandfaxtwo">
                  Tel: 0481 122 567 / 07 3172 0258s
                </div>
                <div class="telandfax"></div>
                <div class="telandfax">
                  Shop 65/358 Cnr Mains Rd &, McCullough St, Sunnybank QLD 4109
                </div>
              </div>

              <div class="fistmonkeychild"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="boxnew">
        <div class="oVictoria">Western Australia</div>
        <div class="boxsonss">
          <div class="monkeyfahter wow animate fadeInUp delay-300ms">
            <div class="monkeybott">
              <div class="fistmonkeychild">
                <div class="copyrigo">
                  <img
                    class="imgoned"
                    src="../assets/img/alltitle/copyin.png"
                    alt=""
                  />
                  <span>E-new Choice Appliance</span>
                </div>
                <div class="telandfax telandfaxtwo">Tel: 0470 396 388</div>
                <div class="telandfax"></div>
                <div class="telandfax">6/8 Fisher Street Belmont, WA 6104</div>
              </div>

              <div class="fistmonkeychild"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="boxnew">
        <div class="oVictoria">South Australia</div>
        <div class="boxsonss">
          <div class="monkeyfahter wow animate fadeInUp delay-300ms">
            <div class="monkeybott">
              <div class="fistmonkeychild">
                <div class="copyrigo">
                  <img
                    class="imgoned"
                    src="../assets/img/alltitle/copyin.png"
                    alt=""
                  />
                  <span>FOTILE Adelaide Store</span>
                </div>
                <div class="telandfax telandfaxtwo">Tel: 08 8231 5974</div>
                <div class="telandfax"></div>
                <div class="telandfax">
                  88 Walkerville Terrace, Walkerville SA 5081
                </div>
              </div>

              <div class="fistmonkeychild"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { WOW } from "wowjs";
import {
  getdealer,
  getztcountrylist,
  getcitylist,
  getshoplist,
} from "../request/api";

export default {
  data() {
    return {
      options: [
        // {
        //   value: "选项1",
        //   label: "Asia",
        // },
        // {
        //   value: "选项2",
        //   label: "Australia",
        // },
        // {
        //   value: "选项3",
        //   label: "North America",
        // },
      ],
      sencondselect: [],
      thirdselect: [],
      value: "",
      two: "",
      there: "",
      flage: "",
      diffent: "",
      nextid: "",
      objmesage: "",
      objlang: "",
      queryid: "",
      inquid: "",
      bottomlist: [],
    };
  },
  created() {
    if (sessionStorage.getItem("newlangusge") == null) {
      this.outdealer("au", "en");
      this.objmesage = "au";
      this.objlang = "en";
    } else {
      this.outdealer("au", sessionStorage.getItem("newlangusge"));
      this.objmesage = "au";
      this.objlang = sessionStorage.getItem("newlangusge");
    }
    this.$route.meta.title = this.$t("login.querythere7");
    this.$router.replace({
      query: {
        temp: Date.now(), //必有
      },
    });
    this.$bus.$on("add", (message, b, d) => {
      console.log(message);
      console.log(b);
      this.objmesage = message;
      this.objlang = b;
      this.outdealer(message, b);
      this.$route.meta.title = this.$t("login.querythere7");
      this.$router.replace({
        query: {
          temp: Date.now(), //必有
        },
      });
    });
  },
  computed: {
    placeholder1() {
      return this.$t("login.pleachoose");
    },
    placeholder2() {
      return this.$t("login.pleachoose");
    },
    placeholder3() {
      return this.$t("login.pleachoose");
    },
  },

  methods: {
    outshopping(a, b, c) {
      getshoplist({
        lang: b,
        country: a,
        city_id: c,
      }).then((res) => {
        console.log(res);
        this.bottomlist = res.data;
      });
    },
    outcitylist(a, b) {
      getcitylist({
        lang: a,
        country_id: b,
      }).then((res) => {
        console.log(res);
        this.thirdselect = res.data;
      });
    },
    outcountrylist(a, b) {
      getztcountrylist({
        lang: a,
        area_id: b,
        country: "au",
      }).then((res) => {
        console.log(res);
        this.sencondselect = res.data;
      });
    },
    outdealer(a, b) {
      getdealer({
        lang: b,
        country: a,
      }).then((res) => {
        console.log(res);
        this.options = res.data.area;
      });
    },
    toinquery() {
      console.log(111111);
      if (this.value == "" || this.two == "" || this.there == "") {
        // this.$message.error("Please select the full address");
        this.$message({
          message: "Please select the full address",
          type: "error",
          offset: 90,
        });
      } else {
        setTimeout(() => {
          // this.value = "";
          // this.two = "";
          // this.there = "";
          // this.$message({
          //   message: "success",
          //   type: "success",
          //   offset: 90,
          // });
        }, 1000);
        this.outshopping(this.objmesage, this.objlang, this.inquid);
      }
    },
    choosearea(el) {
      console.log(el);
      this.flage = el;
      this.two = "";
      this.there = "";
      this.options.forEach((val) => {
        if (this.flage == val.name) {
          this.nextid = val.id;
        }
      });
      console.log(this.nextid);
    },
    plcechose() {
      console.log(this.flage);
      this.outcountrylist(this.objlang, this.nextid);
      // if (this.flage == "选项1") {
      //   this.sencondselect = [
      //     {
      //       value: "1",
      //       label: "Malaysia",
      //     },
      //     {
      //       value: "2",
      //       label: "Pakistan",
      //     },
      //   ];
      // } else if (this.flage == "选项2") {
      //   this.sencondselect = [
      //     {
      //       value: "3",
      //       label: "Australia",
      //     },
      //   ];
      // } else if (this.flage == "选项3") {
      //   this.sencondselect = [
      //     {
      //       value: "4",
      //       label: "America",
      //     },
      //     {
      //       value: "5",
      //       label: "Canada",
      //     },
      //   ];
      // }
    },
    choosesencond(el) {
      console.log(el);
      this.diffent = el;
      this.there = "";
      this.sencondselect.forEach((val) => {
        if (this.diffent == val.name) {
          this.queryid = val.id;
        }
      });
      console.log(this.queryid);
    },
    behind() {
      this.outcitylist(this.objlang, this.queryid);
      // if (this.diffent == 1) {
      //   this.thirdselect = [
      //     {
      //       value: "1",
      //       label: "Kuala Lumpur",
      //     },
      //     {
      //       value: "2",
      //       label: "Saint John",
      //     },
      //     {
      //       value: "3",
      //       label: "韩国",
      //     },
      //     {
      //       value: "4",
      //       label: "Korea",
      //     },
      //   ];
      // } else if (this.diffent == 2) {
      //   this.thirdselect = [
      //     {
      //       value: "1",
      //       label: "KARACHI",
      //     },
      //     {
      //       value: "2",
      //       label: "ISLAMABAD",
      //     },
      //     {
      //       value: "3",
      //       label: "LAHORE",
      //     },
      //   ];
      // } else if (this.diffent == 3) {
      //   this.thirdselect = [
      //     {
      //       value: "1",
      //       label: "Melbourne",
      //     },
      //     {
      //       value: "2",
      //       label: "Sydney",
      //     },
      //     {
      //       value: "3",
      //       label: "Perth",
      //     },
      //     {
      //       value: "4",
      //       label: "Brisbane",
      //     },
      //   ];
      // } else if (this.diffent == 4) {
      //   this.thirdselect = [
      //     {
      //       value: "1",
      //       label: "New York NY",
      //     },
      //     {
      //       value: "2",
      //       label: "New York NY",
      //     },
      //     {
      //       value: "3",
      //       label: "San Francisco CA",
      //     },
      //   ];
      // } else if (this.diffent == 5) {
      //   this.thirdselect = [
      //     {
      //       value: "1",
      //       label: "Vancouver",
      //     },
      //     {
      //       value: "2",
      //       label: "Los Angeles",
      //     },
      //     {
      //       value: "3",
      //       label: "Great Toronto Area",
      //     },
      //   ];
      // }
    },
    choolast(el) {
      console.log(el);
      this.thirdselect.forEach((val) => {
        if (el == val.name) {
          this.inquid = val.id;
        }
      });
      console.log(this.inquid);
    },
  },
  mounted() {
    if (document.documentElement.scrollTop > 0) {
      document.documentElement.scrollTop = 0;
    }
    // document.documentElement.scrollTop = 0;
    // window.addEventListener("scroll", '0');
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      const wow = new WOW({
        offset: 0,
        // mobile: true,
        live: true,
      });
      wow.init();
    });
  },
};
</script>
<style lang="less" scoped>
.exhibition {
  margin-bottom: 50px;
  .hibitiontop {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .hibitionimg {
      .immmon {
        width: 100%;
        // height: 840px;
      }
    }
    .centernav {
      margin: 0 auto;
      width: 1200px;
      height: 100px;
      background: #ffffff;
      box-shadow: 0px 7px 10px 0px rgba(117, 117, 117, 0.09);
      border-radius: 3px;
      margin-top: -60px;
      .centernavinner {
        margin: 25px;
        display: flex;
        .selectarea {
          width: 986px;
          height: 46px;
          background-color: #f7f7f7;
          border-radius: 3px 0px 0px 3px;
          display: flex;
          align-items: center;
          border: 1px solid #f2f2f2;
          /deep/ .el-select .el-input__inner {
            background: #f5f5f5;
            // border: 1px solid #e1e1e1;
            border: none;
            height: 48px;
            width: 300px;
            border-radius: 7px;
          }
          /deep/ .el-select__caret {
            -webkit-transform: none;
            transform: none;
          }

          /deep/ .el-icon-arrow-up:before {
            content: "\e790";
          }
          .mapclass {
            margin-left: 28px;
            display: none;
          }
          .rightlion {
            width: 1px;
            height: 12px;

            background: #898989;
            margin-left: 20px;
            margin-right: 12px;
            opacity: 0.2;
          }
        }
        // .selectarea {
        //   width: 986px;
        //   height: 46px;
        //   background-color: #f7f7f7;
        //   border-radius: 3px 0px 0px 3px;
        //   display: flex;
        //   align-items: center;
        //   border: 1px solid #f2f2f2;
        //   .areapear {
        //     display: flex;
        //     width: 300px;
        //     // height: 65px;
        //     justify-content: space-between;
        //     margin-left: 28px;
        //     border-right: 1px solid #e1e1e1;
        //     padding-right: 30px;
        //     align-items: center;
        //     .mapclassfather {
        //       .mapclass {
        //         width: 14px;
        //         height: 14px;
        //         vertical-align: middle;
        //       }
        //       .setect {
        //         margin-left: 9px;
        //         vertical-align: middle;
        //         font-size: 12px;
        //         // font-family: Arial;
        //         font-weight: 400;
        //         color: #898989;
        //       }
        //     }
        //     .bottnick {
        //       width: 14px;
        //       height: 14px;
        //       vertical-align: middle;
        //     }
        //   }
        //   .areapear:last-child {
        //     border-right: none;
        //   }
        // }
        .selectblack {
          cursor: pointer;
          width: 165px;
          height: 46px;
          font-size: 14px;
          font-weight: bold;
          background: #131e29;
          border: 1px solid #131e29;
          border-radius: 0px 3px 3px 0px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
        }
        .selectblack:hover {
          background: #9d2736;
          border: 1px solid #9d2736;
        }
      }
    }
    .monkeyfahter {
      width: 400px;
      padding-top: 30px;
      .imgfae {
        margin-top: 66px;
        margin-bottom: 66px;
        width: 100%;
        // height: 532px;
      }
      .monkeybott {
        display: flex;
        justify-content: space-between;
        // margin-top:42px;
        flex-wrap: wrap;
        .fistmonkeychild {
          margin-bottom: 60px;
          // width: 30%;
          .copyrigo {
            display: flex;
            align-items: center;
            .imgoned {
              width: 24px;
              // height: 40px;
              // vertical-align: middle;
            }
            span {
              // margin-top: 20px;
              // vertical-align: bottom;
              margin-left: 10px;
              font-size: 18px;
              // font-family: Arial;
              font-weight: bold;
              color: #000000;
            }
          }
          .telandfax {
            font-size: 14px;
            // font-family: Arial;
            width: 300px;
            font-weight: 400;
            color: #000000;
            line-height: 24px;
            margin-left: 32px;
          }
          .telandfaxtwo {
            margin-top: 15px;
          }
        }
      }
    }
  }
}
.boxnew {
  width: 1200px;
  display: flex;

  flex-direction: column;
  // align-items: center;
  // justify-content: center;
  margin: 0 auto;
  margin-bottom: -60px;
}
.boxsonss {
  width: 1200px;
  display: flex;
  // justify-content: space-around;
  // align-items: center;
  // justify-content: center;
  margin: 0 auto;
}
.oVictoria {
  margin-top: 70px;
  widows: 1200px;
  border-bottom: 1px solid #dbdada;
  padding-bottom: 20px;

  font-size: 18px;
  // font-family: Arial;
  font-weight: bold;
  color: #000000;
}
</style>